
function Legal() {

    return (
        <div className="section about">
            <div className="intro-content-about">
                <div className=" w-container">

                    <h1>IMPRESSUM </h1>
                    <br />
                    Pierre Xavier Raphael Fernand Beaulieu  <br />
                    Hausbergtal 1 <br />
                    6370 Kitzbühel, Österreich <br />
                    +43 676 6803450 <br />
                    pierre@mypierre.at oder retroconcept@hotmail.fr <br />
                    <br />
                    Rechtsform: Einzelunternehmen  <br />
                    Gewerbeart: freies Gewerbe gemäß § 5 Abs. 2 GewO <br />
                    GISA-Zahl: 28914792  <br />
                    Gewerbebehörde: Bezirkshauptmannschaft Kitzbühel <br />
                    Zuständige Kammer: WK Tirol  <br />
                    Umsatzsteuerbefreit gemäß § 6 Abs 1 Z 27 UStG <br />
                    <br />
                    Inhalt: Die Inhalte und Bildmaterialien sind urheberrechtlich geschützt - sie zu <br />
                    kopieren oder zu verändern ist nur mit Genehmigung des Urhebers gestattet. <br />
                    Design und Fotos: Pierre Beaulieu <br />
                    <br />
                    <a style={{fontWeight: "bold"}} href="https://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=958db8ca-7a68-4ae7-8a30-
64f0db3ad66e&amp;StandortID=0&amp;Suchbegriff=Pierre+Beaulieu&amp;Page=1">Link zum Firmen A-Z der WKO </a>
                    <br />
                    Diese Website stellt Informationen zu Produkten und Leistungen unseres
                    Unternehmens dar. <br />
                    <br />
                    Sämtliche anwendbaren Bestimmungen, insbesondere GewO und ECG, sind
                    jederzeit unter www.ris.bka.gv.at online abrufbar.
                </div>
            </div>
        </div>
    )
}

export default Legal