import "./product.css"


function Product(props) {
    return (
        <div className="product-wrap">
            <a href={props.link} className="product-item">
                <img src={props.src} alt={props.alt} />
                <div className="product-name">{props.name}</div>
                <div className="product-price">{props.price}€</div>
            </a>
        </div>
    )
}
export default Product