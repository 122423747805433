const text = {
    tabLabel: {
        en: "Handmade in Austria",
        fr: "Fait main en Autriche",
        de: "Handgemacht in Österreich"
    },
    nav: {
        products: {
            en: "products",
            fr: "produits",
            de: "produkte"
        },
        atelier: {
            en: "workshop",
            fr: "atelier",
            de: "atelier"
        },
        surmesure: {
            en: "custom-made",
            fr: "sur mesure",
            de: "auf Maß"
        },
        about: {
            en: "about",
            fr: "a propos",
            de: "über mich"
        },
        contact: {
            en: "contact",
            fr: "contact",
            de: "kontakt"
        },
        home: {
            en: "home",
            fr: "accueil",
            de: "startseite"
        }
    },
    home: {
        title1: {
            en: "my favorites",
            fr: "mes favoris",
            de: "mein Favoriten"
        },
        favorite1: {
            en: "handbag",
            fr: "sac à main",
            de: "beuteltasche"
        }
        ,
        favorite2: {
            en: "modern pouchbag",
            fr: "sac à main moderne",
            de: "moderne beuteltasche"
        }
        ,
        favorite3: {
            en: "cover for notebook",
            fr: "housse pour notebook",
            de: "hülle für notebook"
        }
        ,
        favorite4: {
            en: "card case",
            fr: "porte-cartes",
            de: "kartenetui"
        },
        title2: {
            en: "leather bags and\n accessories since 2011",
            fr: "sacs en cuir et\n accessoires depuis 2011",
            de: "Ledertaschen und\n Accessoires seit 2011"
        },
        text2: {
            en: "I can offer you a wide range of handmade leather items such as women’s or men's hand bags, shoulder bags and accessories. All products are high quality with excellent workmanship.",
            fr: "Je peux vous proposer une large gamme d'articles en cuir faits main, tels que des sacs à main pour femmes ou pour hommes, des sacs à bandoulière et des accessoires. Tous les produits sont de haute qualité avec un excellent travail.",
            de: "Ich biete Ihnen eine große Auswahl an handgefertigten Lederwaren wie Damen- und Herrenhandtaschen, Umhängetaschen und Accessoires. Alle Produkte sind von hoher Qualität und ausgezeichneter Verarbeitung."
        },
        title3: {
            en: "care for pierre",
            fr: "care for pierre",
            de: "care for pierre"
        },
        text3: {
            en: "Pierre uses mostly leather produced in France due to the quality and natural processing. These leathers have an irregular grain most of the time which is a normal characteristic. We recommend to avoid contact with water, oily substances, direct sunlight and light-colored clothes. By using your product regularly it will get a warm patina and obtain a nicer look.\n In case of unwanted dirt marks please contact us for cleaning recommendation. However, please note that we cannot guarantee the outcome resulting from our advice.",
            fr: "Pierre utilise principalement du cuir produit en France en raison de sa qualité et de son traitement naturel. Ces cuirs ont un grain irrégulier la plupart du temps, ce qui est une caractéristique normale. Nous vous recommandons d'éviter le contact avec l'eau, les substances huileuses, la lumière directe du soleil et les vêtements de couleur claire. En utilisant votre produit régulièrement, il obtiendra une patine chaude et un aspect plus agréable.\n En cas de traces de saleté indésirables, veuillez nous contacter pour obtenir des conseils de nettoyage. Cependant, veuillez noter que nous ne pouvons pas garantir le résultat résultant de nos conseils.",
            de: "Ich verwendet hauptsächlich Leder aus Frankreich, aufgrund der Qualitäts- und natürlichen Verarbeitungsmethoden. Meistens hat dieses Leder eine unregelmäßige Maserung, dies ist eine natürliche Eigenschaft. Wir empfehlen den Kontakt mit Wasser, öligen Substanzen, direktem Sonnenlicht und hellen Kleidungsstücken zu vermeiden. Bei regelmäßiger Verwendung bekommt Ihr Produkt eine schöne Patina und wird veredelt.\n Kontaktieren Sie mich bitte für Reinigungstipps im Falle von unerwünschten Flecken. Ich weise jedoch darauf hin, dass ich das Resultat unserer Reinigungstipps nicht garantieren kann."
        },
    },
    about: {
        title1: {
            en: "about pierre",
            fr: "à propos de pierre",
            de: "über pierre"
        },
        text1: {
            en: "I grew up in Provence, south of France, and have always been interested in crafting alongside my professional career. \n My love for traditional leather craftsmanship and creation with my hands led me to turn my hobby into my profession.",
            fr: "J'ai grandi en Provence, dans le sud de la France, et j'ai toujours été intéressé par l'artisanat en parallèle de ma carrière professionnelle. \n Mon amour pour l'artisanat traditionnel du cuir et la création avec mes mains m'a conduit à faire de mon hobby mon métier.",
            de: "Ich bin in der Provence in Südfrankreich aufgewachsen und habe mich neben meinem Beruf schon immer für das Handwerk interessiert. \n Meine Liebe zum traditionellen Lederhandwerk und zur Gestaltung mit meinen Händen hat mich dazu gebracht, mein Hobby zum Beruf zu machen."
        }
        ,
        title2: {
            en: "leather!",
            fr: "cuir!",
            de: "leder!"
        }
        ,
        text2: {
            en: "I worked with many different materials before I dedicated myself entirely to my passion, my favourite material, leather.\n Leather is soft and delicate, but also strong and powerful. It tells the story of its owner and underlines his personality.",
            fr: "J'ai travaillé avec de nombreux matériaux différents avant de me consacrer entièrement à ma passion, mon matériau préféré, le cuir.\n Le cuir est doux et délicat, mais aussi fort et puissant. Il raconte l'histoire de son propriétaire et souligne sa personnalité.",
            de: "Ich habe mit vielen verschiedenen Materialien gearbeitet, bevor ich mich ganz meiner Leidenschaft, meinem Lieblingsmaterial, dem Leder, widmete.\n Leder ist weich und zart, aber auch stark und kraftvoll. Es erzählt die Geschichte seines Besitzers und unterstreicht seine Persönlichkeit."
        }
        ,
        title3: {
            en: "everything you need",
            fr: "tout ce qu'il vous faut",
            de: "alles, was Sie brauchen"
        },
        text3: {
            en: "I produce travel bags, handbags, briefcases, money bags, belts, key rings and many other leather goods in my workshop in Kitzbühel.\n All my products are custom-made unique pieces or small series, which I like to customise for you.\n If you are looking for something special or have a precise idea of your dream bag, just contact me. Everything is possible!",
            fr: "Je fabrique des sacs de voyage, des sacs à main, des porte-documents, des sacs à billets, des ceintures, des porte-clés et bien d'autres articles en cuir dans mon atelier à Kitzbühel.\n Tous mes produits sont des pièces uniques sur mesure ou des petites séries, que j'aime personnaliser pour vous.\n Si vous cherchez quelque chose de spécial ou si vous avez une idée précise du sac de vos rêves, contactez-moi. Tout est possible !",
            de: "In meiner Werkstatt in Kitzbühel stelle ich Reisetaschen, Handtaschen, Aktentaschen, Geldbeutel, Gürtel, Schlüsselanhänger und viele andere Lederwaren her.\n Alle meine Produkte sind maßgefertigte Einzelstücke oder Kleinserien, die ich gerne für Sie individualisiere.\n Wenn Sie etwas Besonderes suchen oder eine genaue Vorstellung von Ihrer Traumtasche haben, kontaktieren Sie mich einfach. Alles ist möglich!"
        }
    },
    footer: {
        address: {
            en: "Austria",
            fr: "Autriche",
            de: "Österreich"
        },
        title1: {
            en: "menu",
            fr: "menu",
            de: "menü"
        },
        title2: {
            en: "main rules",
            fr: "engagements",
            de: "verpflichtungen"
        },
        title3: {
            en: "legal",
            fr: "légal",
            de: "legal"
        },
        handmade: {
            en: "handmade",
            fr: "fait main",
            de: "handgefertigt"
        },
        quality: {
            en: "qualitative leathers",
            fr: "cuirs de qualité",
            de: "qualitative Ledern"
        },
        functionnal: {
            en: "functionnal",
            fr: "fonctionnel",
            de: "Funktional"
        },
        notice: {
            en: "legal notice",
            fr: "mentions légales",
            de: "rechtshinweis"
        },
        privacy: {
            en: "privacy policy",
            fr: "confidentialité",
            de: "datenschutzbestimmungen"
        },
        copyright: {
            en: "All Rights Reserved.",
            fr: "Tous droits réservés.",
            de: "Alle Rechte vorbehalten."
        },
        follow: {
            en: "follow",
            fr: "me suivre",
            de: "mir folgen"
        }
    },
    atelier: {
        title: {
            en: "my worshop",
            fr: "mon atelier",
            de: "my Atelier"
        },
        text1: {
            en: "At first it was important for me to arrange this place so that I could practice my technical and material knowledge. I needed a functional surface and well equipped in tools and machines so that nothing interferes with my creative crafting.",
            fr: "Au départ, il était important pour moi d'aménager ce lieu afin de pouvoir mettre en pratique mes connaissances techniques et matérielles. J'avais besoin d'une surface fonctionnelle et bien équipée en outils et machines pour que rien n'interfère avec mon travail de création.",
            de: "Zunächst war es für mich wichtig, diesen Ort so einzurichten, dass ich meine technischen und materiellen Kenntnisse ausüben kann. Ich brauchte eine funktionale Fläche und eine gute Ausstattung an Werkzeugen und Maschinen, damit nichts mein kreatives Handwerk behindert."
        },
        invite: {
            en: "feel free to come visit my atelier!",
            de: "besuchen Sie mein Atelier!",
            fr: "venez vister mon atelier !"
        },
        title2: {
            en: "cosyness",
            fr: "bien-être",
            de: "Einladend"
        },
        text2: {
            en: "Second it was important to me to arrange a cosy and aesthetic place. Most of the time my customers are part of the creative process. I’m not just making an item for them, it’s a creation between my customer and myself.\n That's why it is important that this Atelier is welcoming for visitors and inspiring for designing the perfect uniques.",
            fr: "Ensuite, il était important pour moi d'aménager un endroit confortable et esthétique. La plupart du temps, mes clients font partie du processus de création. Je ne fais pas simplement un article pour eux, c'est une création entre mon client et moi.\n C'est pourquoi il est important que cet atelier soit accueillant pour les visiteurs et source d'inspiration pour la conception de pièces uniques parfaites.",
            de: "Zweitens war es mir wichtig, einen gemütlichen und ästhetischen Ort zu schaffen. Die meiste Zeit sind meine Kunden Teil des kreativen Prozesses. Ich fertige nicht nur einen Gegenstand für sie an, sondern es ist eine Kreation zwischen meinem Kunden und mir.\n Deshalb ist es wichtig, dass das Atelier einladend für Besucher und inspirierend für die Gestaltung der perfekten Unikate ist."
        }
    },
    contact: {
        title: {
            en: "A question?\n Interested in a product?\n Just want to meet?",
            fr: "Une question ?\n Intéressé par un produit ?\n Vous voulez simplement faire connaissance ?",
            de: "Eine Frage?\n Interessiert an einem Produkt?\n Möchten Sie sich einfach nur treffen?"
        },
        name: {
            label: {
                en: "name",
                fr: "nom",
                de: "name"
            },
            placeholder: {
                en: "Enter your name",
                fr: "Entrez votre nom",
                de: "Geben Sie Ihren Namen ein"
            }
        },
        email: {
            label: {
                en: "email",
                fr: "email",
                de: "email"
            },
            placeholder: {
                en: "Enter your email address",
                fr: "Entrez votre adresse email",
                de: "Gib deine E-Mail-Adresse ein"
            }
        },
        message: {
            label: {
                en: "message",
                fr: "message",
                de: "nachricht"
            },
            placeholder: {
                en: "Enter your message",
                fr: "Entrez votre message",
                de: "Ihre Nachricht eingeben"
            }
        },
        send: {
            en: "Send",
            fr: "Envoyer",
            de: "Senden"
        },
        confirmation: {
            headline: {
                en: "Thank you for your message",
                fr: "Merci pour votre message",
                de: "Vielen Dank!"
            },
            body: {
                en: "Your message has been sent. I will get back to you as soon as possible.",
                fr: "Votre message a bien été envoyé. Je reviens vers vous le plus rapidement possible.",
                de: "Ihre Nachricht wurde erfolgreich gesendet. Ich werde mich so bald wie möglich bei Ihnen melden."
            },
        }
    },
    surmesure: {
        title1: {
            en: "custom made",
            fr: "sur mesure",
            de: "sonderanfertigung"
        },
        text1: {
            en: "What I like the most is to craft custom made items that I design and develop for you with the help of drawings and paperboard bags.\n For me, each person is unique and therefore has a different need and style.\n If you are looking for something special or have an exact idea of your dream bag, just contact me. everything is possible!",
            fr: "Ce qui me plaît le plus, c'est de réaliser des articles sur mesure que je conçois et développe pour vous à l'aide de dessins et de sacs en carton. Pour moi, chaque personne est unique et a donc un besoin et un style différents. Si vous cherchez quelque chose de spécial ou si vous avez une idée précise du sac de vos rêves, contactez-moi. Tout est possible !",
            de: "Am liebsten fertige ich Sonderanfertigungen an, die ich mit Hilfe von Zeichnungen und Papptaschen für Sie entwerfe und entwickle.\n Für mich ist jeder Mensch einzigartig und hat daher einen anderen Bedarf und Stil.\n Wenn Sie etwas Besonderes suchen oder eine genaue Vorstellung von Ihrer Traumtasche haben, kontaktieren Sie mich einfach. alles ist möglich!"
        },
        title2: {
            en: "some of my custom made creations",
            fr: "quelques  créations sur mesure",
            de: "einige meiner maßgefertigten Kreationen"
        }
    },
    langLabel: {
        en: {
            en: "English",
            fr: "French",
            de: "German"
        },
        fr: {
            en: "Anglais",
            fr: "Francais",
            de: "Allemand"
        },
        de: {
            en: "Englisch",
            fr: "Französisch",
            de: "Deutsch"
        }
    }
}

export default text