import React, { useContext, useRef } from "react"
import emailjs from '@emailjs/browser';
import { MyContext } from "../../context";
import ProgressiveImage from "react-progressive-graceful-image";
import text from "../../data/text"
import "./contact.css"
import img from "../../assets/img/a_A_question.JPG"
import tinyImg from "../../assets/img/a_A_question_tiny.JPG"
import Slide from 'react-reveal/Slide';
import close from "../../assets/img/close-menu.svg"


function Contact() {

    const context = useContext(MyContext)

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const confirmation = document.querySelector(".overlay");

        emailjs.sendForm('service_tfsfzeq', 'template_ilbu8u4', form.current, 'N1kxZd_FRKmc2aKtT')
            .then((result) => {
                console.log(result.text)
            }, (error) => {
                console.log(error.text)
            })
        confirmation.style.display = "block"
    }

    const closeConfirmation = () => {
        const confirmation = document.querySelector(".overlay");
        confirmation.style.display = "none";

        const form = document.getElementById("wf-form-Get-In-Touch-Form");
        form.reset()
    }

    return (
        <>
            <div className="section">
                <div className="flex-container w-container">
                    <Slide left>
                        <div className="flex-div-text">
                            <h1 className="heading contact">
                                {text.contact.title[context.state.lang]}
                            </h1>
                        </div>
                    </Slide>
                    <div className="image-div-right">
                        <ProgressiveImage src={img} placeholder={tinyImg} >
                            {(src, loading) => (
                                <img
                                    className={`img-side image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="about pierre"
                                    height="400px"
                                />
                            )}
                        </ProgressiveImage>
                    </div>
                </div>
                <div className="flex-container w-container">
                    <div className="get-in-touch-form-wrap">
                        <form
                            ref={form}
                            id="wf-form-Get-In-Touch-Form"
                            name="wf-form-Get-In-Touch-Form"
                            data-name="Get In Touch Form"
                            className="get-in-touch-form"
                            onSubmit={sendEmail}
                        >
                            <div className="credentials-inputs-wrap">
                                <div className="contact-name-field-wrap">
                                    <label htmlFor="name" className="contact-label">{text.contact.name.label[context.state.lang]}</label>
                                    <input required type="text" className="text-field w-input" name="user_name" data-name="Name" placeholder={text.contact.name.placeholder[context.state.lang]} id="Name" />

                                </div>
                                <div className="email-name-field-wrap">
                                    <label htmlFor="Email-2" className="contact-label">{text.contact.email.label[context.state.lang]}</label>
                                    <input required type="email" className="text-field w-input" name="user_email" data-name="Email" placeholder={text.contact.email.placeholder[context.state.lang]} id="Email-2" />

                                </div>
                            </div>
                            <label className="contact-label">{text.contact.message.label[context.state.lang]}</label>
                            <textarea id="field" name="message" placeholder={text.contact.message.placeholder[context.state.lang]} data-name="Field" className="text-field cc-textarea w-input" />
                            <input required type="submit" value={text.contact.send[context.state.lang]} data-wait="Please wait..." className="button w-button" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="overlay">
                <div className="confirmation">
                    <img src={close}
                        width="20"
                        alt="Close menu"
                        className="menu-icon close"
                        onClick={closeConfirmation} />
                    <div className="headline">{text.contact.confirmation.headline[context.state.lang]}</div>
                    <div className="text-block">{text.contact.confirmation.body[context.state.lang]}</div>
                </div>
            </div>
        </>
    )
}

export default Contact