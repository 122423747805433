import React, {useContext, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import text from "./data/text";
import { MyContext } from "./context";
import Home from "./components/home/home";
import Atelier from "./components/atelier/atelier";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import SurMesure from "./components/surmesure/surmesure";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import Legal from "./components/legal/legal";
import Privacy from "./components/privacy/privacy";
import "./assets/css/styles.css"
import "./assets/fonts/fonts.css"


function App() {
  const context = useContext(MyContext)

  useEffect(() => {
    document.title = `${"Pierre - " + text.tabLabel[context.state.lang]}`;
  }, [context.state.lang]);

  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="atelier" element={<Atelier />} />
        <Route path="contact" element={<Contact />} />
        <Route path="surmesure" element={<SurMesure />} />
        <Route path="contact" element={<Contact />} />
        <Route path="legal" element={<Legal />} />
        <Route path="privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
