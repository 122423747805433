import React, { useContext } from "react";
import { MyContext } from "../../context";
import ProgressiveImage from "react-progressive-graceful-image"
import text from "../../data/text"

import img1 from "../../assets/img/a_About_Pierre.jpg"
import img2 from "../../assets/img/a_Leather.JPG"
import img3 from "../../assets/img/a_Everything_you_need.JPG"
import tinyImg1 from "../../assets/img/a_About_Pierre_tiny.jpg"
import tinyImg2 from "../../assets/img/a_Leather_tiny.JPG"
import tinyImg3 from "../../assets/img/a_Everything_you_need_tiny.JPG"
import Slide from 'react-reveal/Slide';

function About() {

    const context = useContext(MyContext)

    return (
        <div className="section about">
            <div className="intro-content-about">
                <div className="flex-container w-container">
                    <Slide left>
                        <div className="flex-div-text">
                            <div className="heading heading-about">{text.about.title1[context.state.lang]}</div>
                            <div className="intro-text">
                                <div className="text-block">
                                    {text.about.text1[context.state.lang]}
                                </div>
                            </div>
                        </div>
                    </Slide >
                    <div className="image-div-right">

                        <ProgressiveImage src={img1} placeholder={tinyImg1} >
                            {(src, loading) => (
                                <img
                                    className={`img-side image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="about pierre"
                                    height="400px"
                                />
                            )}
                        </ProgressiveImage>

                    </div>
                </div>
            </div>
            <div className="intro-content-about">
                <div className="flex-container w-container">
                    <div className="image-div-left">

                        <ProgressiveImage src={img2} placeholder={tinyImg2} >
                            {(src, loading) => (
                                <img
                                    className={`img-side image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="leather!"
                                    height="400px"
                                />
                            )}
                        </ProgressiveImage>

                    </div>
                    <Slide right>
                        <div className="flex-div-text">
                            <div className="heading heading-about">{text.about.title2[context.state.lang]}</div>
                            <div className="text-block">
                                {text.about.text2[context.state.lang]}
                            </div>
                        </div>
                    </Slide >
                </div>
            </div>
            <div className="intro-content-about">
                <div className="flex-container w-container">
                    <Slide left>
                        <div className="flex-div-text">
                            <div className="heading heading-about">{text.about.title3[context.state.lang]}</div>
                            <div className="text-block about2">
                                {text.about.text3[context.state.lang]}
                            </div>
                        </div>
                    </Slide >
                    <div className="image-div-right">
                        <ProgressiveImage src={img3} placeholder={tinyImg3} >
                            {(src, loading) => (
                                <img
                                    className={`img-side image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="everything you need"
                                    height="400px"
                                />
                            )}
                        </ProgressiveImage>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About