import React, { useContext } from "react"
import { MyContext } from "../../context"
import { Link } from "react-router-dom"
import text from "../../data/text"
import logo from "../../assets/img/Logo.svg"
import "./footer.css"

function Footer() {

    const context = useContext(MyContext)

    return (
        <footer>
            <div className="footer-grid container">
                <div className="contact-footer">
                    <img src={logo} width="80" alt="" className="logo-footer" />
                    <div className="text-block-footer">Pierre Beaulieu<br />Oberleitenweg 56, <br />6370 Kitzbühel <br />{text.footer.address[context.state.lang]}<br /><a href="mailto:pierre@mypierre.at">pierre@mypierre.at</a> <br />+43 676 6803450</div>
                </div>
                <div>
                    <div className="label label-footer">{text.footer.title1[context.state.lang]}</div>
                    <div className="links-section-footer">
                        <Link to="/" className="footer-link">{text.nav.home[context.state.lang]}</Link>
                        <Link to="about" aria-current="page" className="footer-link w--current">{text.nav.about[context.state.lang]}</Link>
                        <Link to="contact" className="footer-link">{text.nav.contact[context.state.lang]}</Link>
                        <a href="https://www.etsy.com/de/shop/MyPierreShop" className="footer-link">{text.nav.products[context.state.lang]}</a>
                    </div>
                </div>
                <div>
                    <div className="label label-footer">{text.footer.title2[context.state.lang]}</div>
                    <div className="links-section-footer">
                        <Link to="" className="footer-link cursor">{text.footer.handmade[context.state.lang]}</Link>
                        <Link to=""className="footer-link cursor">{text.footer.quality[context.state.lang]}</Link>
                        <Link to="" className="footer-link cursor">{text.footer.functionnal[context.state.lang]}</Link>
                    </div>
                </div>
                <div>
                    <div className="label label-footer">{text.footer.title3[context.state.lang]}</div>
                    <div className="links-section-footer">
                        <Link to="legal" className="footer-link">{text.footer.notice[context.state.lang]}</Link>
                        <Link to="privacy" className="footer-link">{text.footer.privacy[context.state.lang]}</Link>
                    </div>
                </div>
                <div>
                    <div className="label label-footer">{text.footer.follow[context.state.lang]}</div>
                    <div className="links-section-footer">
                        <a href="https://www.instagram.com/pierre.theofficial/" className="footer-link">Instagram</a>
                        <a href="https://www.facebook.com/MyPierre.Beaulieu/" className="footer-link">Facebook</a>
                    </div>
                </div>
                <div className="copyright">© {new Date().getFullYear()} myPierre. {text.footer.copyright[context.state.lang]}
                </div>
            </div>
        </footer >
    )
}

export default Footer