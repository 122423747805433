import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom"
import { MyContext } from "../../context";
import Slide from 'react-reveal/Slide';

import "./header.css"
import logo from "../../assets/img/Logo.svg"
import bars from "../../assets/img/bars.svg"
import close from "../../assets/img/close-menu.svg"
import text from "../../data/text"

function Header() {

    const [isOpen, setIsOpen] = useState(false)
    const [openDropdown, setOpenDropdown] = useState(false)

    const context = useContext(MyContext)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    const toggleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }

    return (
        <div className="header">
            <div className="container">
                <div className="large">
                    <nav className="navigation-items">
                        <div className="navigation-wrap">
                            <a href="https://www.etsy.com/de/shop/MyPierreShop" className="navigation-item">{text.nav.products[context.state.lang]}</a>
                            <NavLink to="atelier" className="navigation-item">{text.nav.atelier[context.state.lang]}</NavLink>
                            <NavLink to="surmesure" className="navigation-item">{text.nav.surmesure[context.state.lang]}</NavLink>
                        </div>
                        <div className="logo-container">
                            <Link to="/" className="logo-link w-nav-brand"><img src={logo} width="100" alt="" className="logo-image" /></Link>
                            <div className="logo-label">
                                handmade in austria
                            </div>
                        </div>
                        <div className="navigation-wrap right">
                            <NavLink to="about" className="navigation-item">{text.nav.about[context.state.lang]}</NavLink>
                            <NavLink to="contact" className="navigation-item">{text.nav.contact[context.state.lang]}</NavLink>

                            <div className="dropdown">
                                <div className="dropdown-header" onClick={toggleDropdown}>{text.langLabel[context.state.lang][context.state.lang]}</div>

                                {
                                    openDropdown &&
                                    <div className="dropdown-list-container">
                                        <div className="dropdown-list">
                                            {context.state.lang !== "en" &&
                                                <div
                                                    className="list-item"
                                                    onClick={() => { context.langHandler("en"); toggleDropdown() }}
                                                >
                                                    english
                                                </div>
                                            }
                                            {context.state.lang !== "de" &&

                                                <div
                                                    className="list-item"
                                                    onClick={() => { context.langHandler("de"); toggleDropdown() }}
                                                >
                                                    deutsch
                                                </div>
                                            }
                                            {context.state.lang !== "fr" &&

                                                <div
                                                    className="list-item"
                                                    onClick={() => { context.langHandler("fr"); toggleDropdown() }}
                                                >
                                                    français

                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </nav >
                </div>

                <div className="small">
                    <div></div>
                    <div className="logo-div">
                        <Link to="/" className="logo-link w-nav-brand"><img src={logo} width="90" alt="" className="logo-image" /></Link>
                        <div className="logo-label">
                            handmade in austria
                        </div>
                    </div>
                    <nav className="navigation-items">
                        <div className="navigation-wrap">
                            <img
                                src={bars}
                                width="20"
                                alt=""
                                className="menu-icon"
                                onClick={toggleMenu} />
                        </div>
                    </nav>
                    {
                        isOpen &&

                        <Slide right duration={400}>
                            <div className="menu">
                                <NavLink
                                    to="/"
                                    className="navigation-item"
                                    onClick={toggleMenu}
                                >
                                    {text.nav.home[context.state.lang]}
                                </NavLink>
                                <img src={close}
                                    width="20"
                                    alt="Close menu"
                                    className="menu-icon close"
                                    onClick={toggleMenu} />
                                <a href="https://www.etsy.com/de/shop/MyPierreShop" className="navigation-item">{text.nav.products[context.state.lang]}</a>

                                <NavLink
                                    to="atelier"
                                    className="navigation-item"
                                    onClick={toggleMenu}
                                >
                                    {text.nav.atelier[context.state.lang]}
                                </NavLink>
                                <NavLink
                                    to="surmesure"
                                    className="navigation-item"
                                    onClick={toggleMenu}
                                >
                                    {text.nav.surmesure[context.state.lang]}
                                </NavLink>
                                <NavLink
                                    to="about"
                                    className="navigation-item"
                                    onClick={toggleMenu}
                                >
                                    {text.nav.about[context.state.lang]}
                                </NavLink>
                                <NavLink
                                    to="contact"
                                    className="navigation-item"
                                    onClick={toggleMenu}
                                >
                                    {text.nav.contact[context.state.lang]}
                                </NavLink>

                                <div className="dropdown">
                                    <div className="dropdown-header" onClick={toggleDropdown}>{text.langLabel[context.state.lang][context.state.lang]}</div>

                                    {
                                        openDropdown &&
                                        <div className="dropdown-list-container">
                                            <div className="dropdown-list">
                                                {context.state.lang !== "en" &&
                                                    <div
                                                        className="list-item"
                                                        onClick={() => { context.langHandler("en"); toggleDropdown() }}
                                                    >
                                                        english
                                                    </div>
                                                }
                                                {context.state.lang !== "de" &&

                                                    <div
                                                        className="list-item"
                                                        onClick={() => { context.langHandler("de"); toggleDropdown() }}
                                                    >
                                                        deutsch
                                                    </div>
                                                }
                                                {context.state.lang !== "fr" &&

                                                    <div
                                                        className="list-item"
                                                        onClick={() => { context.langHandler("fr"); toggleDropdown() }}
                                                    >
                                                        français

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Slide>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header