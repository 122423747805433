import React from "react";
import "./gallery.css"

import Gallery from 'react-grid-gallery';

import img1 from "../../assets/img/gallery_1.jpg"
import thumb1 from "../../assets/img/gallery_1_thumbnail.jpg"

import img2 from "../../assets/img/gallery_2.jpg"
import thumb2 from "../../assets/img/gallery_2_thumbnail.jpg"

import img3 from "../../assets/img/gallery_3.jpg"
import thumb3 from "../../assets/img/gallery_3_thumbnail.jpg"

import img4 from "../../assets/img/gallery_4.jpg"
import thumb4 from "../../assets/img/gallery_4_thumbnail.jpg"

import img5 from "../../assets/img/gallery_5.jpg"
import thumb5 from "../../assets/img/gallery_5_thumbnail.jpg"

import img6 from "../../assets/img/gallery_6.jpg"
import thumb6 from "../../assets/img/gallery_6_thumbnail.jpg"

import img7 from "../../assets/img/gallery_13.jpg"
import thumb7 from "../../assets/img/gallery_13_thumbnail.jpg"

import img8 from "../../assets/img/gallery_8.jpg"
import thumb8 from "../../assets/img/gallery_8_thumbnail.jpg"

import img9 from "../../assets/img/gallery_9.jpg"
import thumb9 from "../../assets/img/gallery_9_thumbnail.jpg"

import img10 from "../../assets/img/gallery_10.jpg"
import thumb10 from "../../assets/img/gallery_10_thumbnail.jpg"

import img11 from "../../assets/img/gallery_11.jpg"
import thumb11 from "../../assets/img/gallery_11_thumbnail.jpg"

import img12 from "../../assets/img/gallery_12.jpg"
import thumb12 from "../../assets/img/gallery_12_thumbnail.jpg"

import img13 from "../../assets/img/gallery_7.jpg"
import thumb13 from "../../assets/img/gallery_7_thumbnail.jpg"


const images =
    [{
        src: img1,
        thumbnail: thumb1,
        thumbnailWidth: 420,
        thumbnailHeight: 174
    },
    {
        src: img2,
        thumbnail: thumb2,
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        src: img3,
        thumbnail: thumb3,
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        src: img4,
        thumbnail: thumb4,
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        src: img5,
        thumbnail: thumb5,
        thumbnailWidth: 200,
        thumbnailHeight: 174
    },
    {
        src: img6,
        thumbnail: thumb6,
        thumbnailWidth: 420,
        thumbnailHeight: 174
    },
    {
        src: img7,
        thumbnail: thumb7,
        thumbnailWidth: 200,
        thumbnailHeight: 174
    },
    {
        src: img8,
        thumbnail: thumb8,
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        src: img9,
        thumbnail: thumb9,
        thumbnailWidth: 320,
        thumbnailHeight: 174
    },
    {
        src: img10,
        thumbnail: thumb10,
        thumbnailWidth: 200,
        thumbnailHeight: 174
    },
    {
        src: img11,
        thumbnail: thumb11,
        thumbnailWidth: 420,
        thumbnailHeight: 174
    },
    {
        src: img13,
        thumbnail: thumb13,
        thumbnailWidth: 200,
        thumbnailHeight: 174
    },
    {
        src: img12,
        thumbnail: thumb12,
        thumbnailWidth: 420,
        thumbnailHeight: 174
    }

    ]

function GalleryGrid() {

    return (
        <div className="container gallery">
            <Gallery
                images={images}
                enableImageSelection={false}
                showImageCount={false}
            />

        </div>
    )
}

export default GalleryGrid