import React, { useContext } from "react"
import { MyContext } from "../../context"

import ProgressiveImage from "react-progressive-graceful-image"

import text from "../../data/text"
import GalleryGrid from "../gallery/gallery"

import img1 from "../../assets/img/surmesure.jpg"
import tinyImg1 from "../../assets/img/surmesure_tiny.jpg"

import Slide from 'react-reveal/Slide'

function SurMesure() {

    const context = useContext(MyContext)

    return (
        <div className="section about">
            <div className="intro-content-about">
                <div className="flex-container w-container">
                    <div className="image-div-left surmesure">
                        <div className="img-container flexbox">
                            <ProgressiveImage
                                src={img1}
                                placeholder={tinyImg1}
                            >
                                {(src, loading) => (
                                    <img
                                        className={`img-side image${loading ? " loading" : " loaded"}`}
                                        src={src}
                                        alt="sur mesure"
                                        height="320px"
                                    />
                                )}
                            </ProgressiveImage>
                        </div>
                    </div>
                    <Slide right>
                        <div className="flex-div-text surmesure">
                            <div className="heading heading-about">{text.surmesure.title1[context.state.lang]}</div>
                            <div className="intro-text">
                                <div className="text-block">
                                    {text.surmesure.text1[context.state.lang]}
                                </div>
                            </div>
                        </div>
                    </Slide >
                </div>
            </div>
            <div className="flex-container w-container">
                <div className="heading heading-about center">{text.surmesure.title2[context.state.lang]}</div>
            </div>
            <div className="flex-container w-container">
                <GalleryGrid />
            </div>
        </div>
    )
}

export default SurMesure