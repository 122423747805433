import React, { useContext } from "react"
import { MyContext } from "../../context"
import ProgressiveImage from "react-progressive-graceful-image"
import text from "../../data/text"
import img3 from "../../assets/img/a_Atelier-1.jpg"
import img2 from "../../assets/img/a_Atelier-2.JPG"
import img1 from "../../assets/img/a_Atelier-3.jpg"
import img4 from "../../assets/img/a_Atelier-4.jpg"

import tinyImg3 from "../../assets/img/a_Atelier-1_tiny.jpg"
import tinyImg2 from "../../assets/img/a_Atelier-2_tiny.JPG"
import tinyImg1 from "../../assets/img/a_Atelier-3_tiny.jpg"
import tinyImg4 from "../../assets/img/a_Atelier-4_tiny.jpg"


import "./atelier.css"
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';


function Atelier() {

    const context = useContext(MyContext)
    return (

        <div className="section">
            <section id="gallery">
                <div className="centered-container w-container">
                    <h1 className="heading atelier">{text.atelier.title[context.state.lang]}</h1>
                    <div className="gallery-grid-container top">
                        <div className="img-container atelier">
                            <ProgressiveImage src={img1} placeholder={tinyImg1} >
                                {(src, loading) => (
                                    <img
                                        className={`gallery-thumbnail img image${loading ? " loading" : " loaded"}`}
                                        src={src}
                                        alt="workshop"
                                        height="400px"
                                    />
                                )}
                            </ProgressiveImage>
                        </div>
                        <Slide right>
                            <div className="atelier-text-right">
                                <h1 className="heading atelier div">savoir<br /> faire</h1>
                                <div className="text-atelier-1">
                                    {text.atelier.text1[context.state.lang]}
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <div></div>
                    <div className="headline-block-atelier">
                        <div className="visit">
                            <div className="headline-atelier">{text.atelier.invite[context.state.lang]}</div>
                            <Fade bottom>
                                <div className="text-address">Oberleitenweg 56, 6370 Kitzbühel - {text.footer.address[context.state.lang]}</div>
                            </Fade>
                        </div>
                    </div>
                    <div className="gallery-grid-container secondary">




                        <ProgressiveImage src={img3} placeholder={tinyImg3} >
                            {(src, loading) => (
                                <img
                                    className={`gallery-thumbnail bottom image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="workshop 2"
                                    height="400px"
                                />

                            )}
                        </ProgressiveImage>
                        <ProgressiveImage src={img2} placeholder={tinyImg2} >
                            {(src, loading) => (
                                <img
                                    className={`gallery-thumbnail bottom center image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="workshop 3"
                                    height="400px"
                                />

                            )}
                        </ProgressiveImage>
                        <ProgressiveImage src={img4} placeholder={tinyImg4} >
                            {(src, loading) => (
                                <img
                                    className={`gallery-thumbnail bottom center image${loading ? " loading" : " loaded"}`}
                                    src={src}
                                    alt="workshop 4"
                                    height="400px"
                                />

                            )}
                        </ProgressiveImage>
                        <Slide right>
                            <div className="div-block-atelier-2">
                                <h1 className="heading atelier div div2">{text.atelier.title2[context.state.lang]}</h1>
                                <div className="text-block-atelier">
                                    {text.atelier.text2[context.state.lang]}
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Atelier